//import cookie from './jquery.cookie';

//const cookie = require('./jquery.cookie');

//require('./jquery.cookie');

import Cookies from 'js-cookie';

$(document).ready(function() {
    if (Cookies.get('cookiebar') === undefined) {
        $('body').append('<div class="cookie-bar" id="cookie-bar">En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de cookies pour réaliser des statistiques de visites anonymes / By continuing to browse this site, you accept the use of cookies to generate anonymous visit statistics. <a href="/fr/privacy">En savoir plus</a> / <a href="/en/privacy">Find out more</a> <div class="cookie_btn" id="cookie_accept">Ok</div><div class="cookie_btn cookie_btn-error" id="cookie_cancel">Refuser les cookies / Refuse cookies</div></div>');

        $('#cookie_accept').click(function(e) {
            e.preventDefault();

            $('#cookie-bar').fadeOut();

            Cookies.set('cookiebar', 'viewed', {expires: 366});
        });

        $('#cookie_cancel').click(function(e) {
            e.preventDefault();

            $('#cookie-bar').fadeOut();

            Cookies.set('cookiebar', 'viewed', {expires: 366});
            Cookies.set('cookiecancel', '1', {expires: 366});
        });
    } else {
        if (Cookies.get('cookiecancel') === undefined) {
            // Google analytics
        }
    }
});
