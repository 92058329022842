var locale;

var Bloodhound = require('./typeahead.bundle');

$(document).ready(function() {
    // document.getElementById('typeahead').onkeypress = function(event) {
    //     if (event.keyCode == 13) {
    //         toProfile(this);

    //         return false;
    //     }
    // };

    document.getElementById('typeahead-form').onsubmit = function(event) {
        event.preventDefault();

        toProfile(document.getElementById('typeahead'));

        return false;
    }

    $.ajax({
        url: "/ajax/locale",
        method: "post"
    }).done(function(msg) {
        locale = JSON.parse(msg['data']);

        $.ajax({
            url: "/ajax/users",
            method: "post"
        }).done(function(msg) {
            var data = JSON.parse(msg['data']);
            var users = [];
    
            for(var i=0; i<data.length; i++) {
                users.push(data[i].username);
            }
            
            // Constructing the suggestion engine
            var users = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                local: users
            });
            
            // Initializing the typeahead
            $('#typeahead').typeahead({
                hint: true,
                highlight: true, // Enable substring highlighting
                minLength: 1 // Specify minimum characters required for showing suggestions
            },
            {
                name: 'users',
                source: users
            });
        });
    });
});

function toProfile(input) {
    $.ajax({
        url: "/ajax/user_by_username",
        method: "post",
        data: {username: input.value}
    }).done(function(msg) {
        var data = JSON.parse(msg['data']);

        if (typeof data[0] != 'undefined' && typeof locale != 'undefined') {
            window.location.href = "/" + locale + "/profile/" + data[0].id;
        }
    });
}
